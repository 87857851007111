import React from "react";
import '../../components/Layout/layout.css';

const CustomStylingText = ({ children }) => {
  return (
    <div className="styled-text">
      {children}
    </div>
  );
};

export default CustomStylingText;
